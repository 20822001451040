import { NLMailboxProfileType } from "@models/Models";
import { ReactComponent as ArrowDiagonal } from "@images/arrow_diagonal.svg";
import IconButton from "@components/common/IconButton";
import SectionLayout from "../../../layouts/SectionLayout";
import Alert, { AlertState } from "@components/common/Alert";
import { useState } from "react";
import ManageConsentsModal from "../manage-consents/ManageConsentsModal";
import { usePremium } from "@providers/PremiumContext";
import { PremiumSource } from "@models/Enums";
import { useAuth } from "@providers/AuthContext";

const HelpSupport = () => {
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [isShowManageConsents, setIsShowManageConsents] = useState<boolean>(false);
	const { isPremium, premiumSource } = usePremium();
	const { authUser } = useAuth();

	const goToLink = (link: string) => {
		window.open(link, "_blank");
	};

	const goToStore = () => {
		var defaultLink = "https://pro.meco.app/p/login/28o03ichKg4jaRi9AA" + "?prefilled_email=" + encodeURIComponent(authUser!.email);

		switch (premiumSource) {
			case PremiumSource.appStore:
				defaultLink = "https://apps.apple.com/account/subscriptions";
				break;
			case PremiumSource.playStore:
				defaultLink = "https://play.google.com/store/account/subscriptions";
				break;
			default:
				break;
		}

		goToLink(defaultLink);

	}

	return (
		<>
			<Alert alertState={alertState} setAlertState={setAlertState} />
			<ManageConsentsModal isShow={isShowManageConsents} onClose={() => setIsShowManageConsents(false)} />
			<SectionLayout id="support" sectionTitle="Help & Support">
				<div className="flex flex-row gap-4 flex-wrap">
					{isPremium() && premiumSource && [PremiumSource.appStore, PremiumSource.stripe].includes(premiumSource) && (
						<button className="group flex items-center justify-between gap-4 bg-surface border hover:bg-surface-100 transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 h-[60px] w-full" onClick={() => goToStore()}>
							<div className="flex min-w-0 flex-grow">
								<div className="font-primary font-medium text-base text-primary text-left truncate">Manage PRO Subscription</div>
							</div>
							<ArrowDiagonal className="fill-primary-500 group-hover:fill-primary w-[14px]" />
						</button>
					)}
					<button className="group flex items-center justify-between gap-4 bg-surface border hover:bg-surface-100 transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 h-[60px] w-full" onClick={() => goToLink("https://www.meco.app/faq")}>
						<div className="flex min-w-0 flex-grow">
							<div className="font-primary font-medium text-base text-primary text-left truncate">FAQs</div>
						</div>
						<ArrowDiagonal className="fill-primary-500 group-hover:fill-primary w-[14px]" />
					</button>
					<button className="group flex items-center justify-between gap-4 bg-surface border hover:bg-surface-100 transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 h-[60px] w-full" onClick={() => setIsShowManageConsents(true)}>
						<div className="flex min-w-0 flex-grow">
							<div className="font-primary font-medium text-base text-primary text-left truncate">Manage your consents</div>
						</div>
					</button>
					<button className="group flex items-center justify-between gap-4 bg-surface border hover:bg-surface-100 transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 h-[60px] w-full" onClick={() => setAlertState({ isShow: true, title: "Requesting a data export", message: "Kindly email us at team@meco.app to request an export of your data, and our team will respond to you as soon as possible.", actionButton: "OK", dismissButton: null, actionButtonAction: undefined })}>
						<div className="flex min-w-0 flex-grow">
							<div className="font-primary font-medium text-base text-primary text-left truncate">Export your data</div>
						</div>
					</button>
					<button className="group flex items-center justify-between gap-4 bg-surface border hover:bg-surface-100 transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 h-[60px] w-full" onClick={() => goToLink("https://www.meco.app/terms")}>
						<div className="flex min-w-0 flex-grow">
							<div className="font-primary font-medium text-base text-primary text-left truncate">Terms & Conditions</div>
						</div>
						<ArrowDiagonal className="fill-primary-500 group-hover:fill-primary w-[14px]" />
					</button>
					<button className="group flex items-center justify-between gap-4 bg-surface border hover:bg-surface-100 transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 h-[60px] w-full" onClick={() => goToLink("https://www.meco.app/privacy")}>
						<div className="flex min-w-0 flex-grow">
							<div className="font-primary font-medium text-base text-primary text-left truncate">Privacy Policy</div>
						</div>
						<ArrowDiagonal className="fill-primary-500 group-hover:fill-primary w-[14px]" />
					</button>
					<button className="group flex items-center justify-between gap-4 bg-surface border hover:bg-surface-100 transition ease-in-out duration-300 border-primary-100 rounded-xl p-3 h-[60px] w-full" onClick={() => goToLink("https://www.x.com/meco_app")}>
						<div className="flex min-w-0 flex-grow">
							<div className="font-primary font-medium text-base text-primary text-left truncate">Follow us on X</div>
						</div>
						<ArrowDiagonal className="fill-primary-500 group-hover:fill-primary w-[14px]" />
					</button>
				</div>
			</SectionLayout>
		</>
	);
};

export default HelpSupport;
