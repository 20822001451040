import * as appAuth from "@openid/appauth";
import * as networkManager from "../networking/NetworkManager";
import { kGoogleConstants } from "../../constants/GoogleConstants";
import * as oauthSignInManager from "./OauthSignInManager";
import { NLOauthAccessToken, NLMailboxProfileType, NLProfile } from "../../../models/Models";
import { kErrorConstants } from "../../constants/ErrorConstants";
import * as analyticsManager from "../AnalyticsManager";
import { kOutlookConstants } from "@utils/constants/OutlookConstants";

class OauthSessionManager {
	private static instance: OauthSessionManager | null = null;

	private constructor() {}

	private tokenStorage: NLOauthAccessToken[] = [];
	unauthorizedMailboxProfileIds = new Set<string>();
	notProMailboxProfileIds = new Set<string>();

	static sharedInstance(): OauthSessionManager {
		if (!OauthSessionManager.instance) {
			OauthSessionManager.instance = new OauthSessionManager();
		}
		return OauthSessionManager.instance;
	}

	public get hasOneOrMoreAccessTokenErrors() {
		return this.unauthorizedMailboxProfileIds.size > 0 || this.notProMailboxProfileIds.size > 0;
	}

	generateSignInRequiredError(mailboxProfileId: string, error?: Error): Error | undefined {
		if (error) {
			analyticsManager.recordEvent("MCO - Refresh Token Error", { error: error.message });
		}
		this.unauthorizedMailboxProfileIds.add(mailboxProfileId);
		return kErrorConstants.mailboxProfiles.oauth.signInRequired;
	}

	generateNotProUserError(mailboxProfileId: string, error?: Error): Error | undefined {
		if (error) {
			analyticsManager.recordEvent("MCO - Not Pro User Error", { error: error.message });
		}
		this.notProMailboxProfileIds.add(mailboxProfileId);
		return kErrorConstants.mailboxProfiles.oauth.notProUser;
	}

	fetchAccessTokenForMailboxProfile(mailboxProfileId: string, mailboxType: string): Promise<string> {
		return new Promise((resolve, reject) => {
			const currentDateInMillis = new Date().getTime();
			const storedAccessToken = this.tokenStorage.filter((x) => x.mailbox_profile_id === mailboxProfileId)[0];
			if (storedAccessToken && storedAccessToken.access_token && storedAccessToken.expires_in && storedAccessToken.expires_in > currentDateInMillis) {
				resolve(storedAccessToken.access_token);
				return;
			}

			switch (mailboxType) {
				case NLMailboxProfileType.Gmail:
					networkManager
						.getGoogleAccessToken(mailboxProfileId)
						.then((oauthAccessToken) => {
							const idx = this.tokenStorage.findIndex((x) => x.mailbox_profile_id === oauthAccessToken.mailbox_profile_id) 
							if (idx !== -1) {
								this.tokenStorage.splice(idx, 1);
							}
							this.tokenStorage.push(oauthAccessToken);
							resolve(oauthAccessToken.access_token);
						})
						.catch((error) => {
							analyticsManager.recordEvent("MCO - Refresh Token Error", { error: `backend_refresh_token_error: ${error.message}` });
							if (error.response.status === 401) {
								reject(this.generateSignInRequiredError(mailboxProfileId));
								return;
							}
							reject(error);
						});
					break;
				case NLMailboxProfileType.Outlook:
					networkManager
						.getOutlookAccessToken(mailboxProfileId)
						.then((oauthAccessToken) => {
							const idx = this.tokenStorage.findIndex((x) => x.mailbox_profile_id === oauthAccessToken.mailbox_profile_id) 
							if (idx !== -1) {
								this.tokenStorage.splice(idx, 1);
							}
							this.tokenStorage.push(oauthAccessToken);
							resolve(oauthAccessToken.access_token);
						})
						.catch((error) => {
							analyticsManager.recordEvent("MCO - Refresh Token Error", { error: `backend_refresh_token_error: ${error.message}` });
							if (error.response.status === 401) {
								reject(this.generateSignInRequiredError(mailboxProfileId));
								return;
							}
							reject(error);
						});
					break;
				default:
					reject(kErrorConstants.mailboxProfiles.unknownError);
					break;
			}
		});
	}

	integrateWithGmail(loginHint?: string): Promise<[NLProfile, string]> {
		return new Promise((resolve, reject) => {
			this.loginWithGmailUsingOpenID(loginHint)
				.then((serverCode) => {
					return this.createGmailMailboxProfile(serverCode, loginHint);
				})
				.then(([profile, mailboxProfileId]) => {
					resolve([profile, mailboxProfileId]);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	private async loginWithGmailUsingOpenID(loginHint?: string) {
		return new Promise<appAuth.StringMap>((resolve, reject) => {
			var additionalParameters = { prompt: "consent", access_type: "offline" };

			if (loginHint) {
				additionalParameters["login_hint"] = loginHint;
			}

			const request = new appAuth.AuthorizationRequest({
				client_id: kGoogleConstants.clientId,
				redirect_uri: kGoogleConstants.redirectUri,
				scope: kGoogleConstants.scopes.join(" "),
				response_type: appAuth.AuthorizationRequest.RESPONSE_TYPE_CODE,
				extras: additionalParameters,
			});

			const configuration = new appAuth.AuthorizationServiceConfiguration({
				authorization_endpoint: kGoogleConstants.authorizationEndpoint,
				token_endpoint: kGoogleConstants.tokenEndpoint,
				revocation_endpoint: kGoogleConstants.revocationEndpoint,
			});

			oauthSignInManager.initiateSignInFlow(request, configuration, NLMailboxProfileType.Gmail, resolve, reject);
		});
	}

	private createGmailMailboxProfile(serverCode: appAuth.StringMap, loginHint?: string): Promise<[NLProfile, string]> {
		return new Promise((resolve, reject) => {
			networkManager
				.createGmailMailboxProfile(serverCode.code, kGoogleConstants.redirectUri, loginHint)
				.then(([profile, mailboxProfileId]) => {
					resolve([profile, mailboxProfileId]);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	integrateWithOutlook(loginHint?: string): Promise<[NLProfile, string]> {
		return new Promise((resolve, reject) => {
			this.loginWithOutlookUsingOpenID(loginHint)
				.then((serverCode) => {
					return this.createOutlookMailboxProfile(serverCode, loginHint);
				})
				.then(([profile, mailboxProfileId]) => {
					resolve([profile, mailboxProfileId]);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	private async loginWithOutlookUsingOpenID(loginHint?: string) {
		return new Promise<appAuth.StringMap>((resolve, reject) => {
			var additionalParameters = { prompt: "consent", access_type: "offline" };

			if (loginHint) {
				additionalParameters["login_hint"] = loginHint;
			}

			const request = new appAuth.AuthorizationRequest({
				client_id: kOutlookConstants.clientId,
				redirect_uri: kOutlookConstants.redirectUri,
				scope: kOutlookConstants.scopes.join(" "),
				response_type: appAuth.AuthorizationRequest.RESPONSE_TYPE_CODE,
				extras: additionalParameters,
			});

			const configuration = new appAuth.AuthorizationServiceConfiguration({
				authorization_endpoint: kOutlookConstants.authorizationEndpoint,
				token_endpoint: kOutlookConstants.tokenEndpoint,
				revocation_endpoint: kOutlookConstants.revocationEndpoint,
			});

			oauthSignInManager.initiateSignInFlow(request, configuration, NLMailboxProfileType.Outlook, resolve, reject);
		});
	}

	private createOutlookMailboxProfile(serverCode: appAuth.StringMap, loginHint?: string): Promise<[NLProfile, string]> {
		return new Promise((resolve, reject) => {
			networkManager
				.createOutlookMailboxProfile(serverCode.code, kOutlookConstants.redirectUri, loginHint)
				.then(([profile, mailboxProfileId]) => {
					resolve([profile, mailboxProfileId]);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
}

export default OauthSessionManager;
