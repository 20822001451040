import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as OptionsIcon } from "@images/options_dots.svg";
import { ReactComponent as DeleteIcon } from "@images/delete_icon.svg";
import { ReactComponent as BookmarkOffIcon } from "@images/bookmark_icon.svg";
import { ReactComponent as BookmarkOnIcon } from "@images/bookmark_on_icon.svg";
import { ReactComponent as LeftArrowIcon } from "@images/left_arrow_icon.svg";
import { ReactComponent as FullScreenIcon } from "@images/full_screen_icon.svg";
import { ReactComponent as FullScreenInvertedIcon } from "@images/full_screen_inverted_icon.svg";
import { useData } from "@providers/DataContext";
import EmptyStateView from "@components/common/EmptyStateView";
import { useLayout } from "@providers/LayoutContext";
import { MailState, useMailsDispatch } from "@providers/MailContext";
import { NLBookmark, NLBookmarkStar, NLMail, NLMailboxProfileType, NLUserMailStateNames } from "@models/Models";
import { AnimatePresence, motion } from "framer-motion";
import { deleteUndeleteMessages, modifyMessages } from "@utils/managers/MailManager";
import { updateBookmark, postBookmarks, postShareMail, postAnalyticsEvents } from "@utils/managers/networking/NetworkManager";
import { useAuth } from "@providers/AuthContext";
import { MesageLabelAction } from "@models/Enums";
import EmailCheckMark from "@components/common/EmailCheckMark";
import { useHotkeys } from "react-hotkeys-hook";
import { useBookmarks, useBookmarksDispatch } from "@providers/BookmarkContext";
import BookmarkTagNoteModal, { BookmarkTagNoteModalState } from "@components/common/BookmarkTagNoteModal";
import { generateToast } from "@utils/managers/ToastManager";
import IconButton from "@components/common/IconButton";
import moment from "moment";
import Dropdown, { DropdownItem } from "@components/common/Dropdown";
import AddGroupModal, { AddGroupState } from "@components/common/AddGroupModal";
import ReaderModePopOver from "@components/common/ReaderModePopOver";
import { defaultStyles, readerModeStyles } from "./EmailStyling";
import { ReaderModeService } from "@utils/managers/backendMailManager/mailboxFunctions/ReaderModeService";
import { generateNewsletterProperties, recordEvent } from "@utils/managers/AnalyticsManager";
import { kAnalyticsConstants } from "@utils/constants/AnalyticsConstants";
import Alert, { AlertState } from "@components/common/Alert";
import ReplyMessageModal, { ReplyMessageModalState } from "@components/common/ReplyMessageModal";
import SummaryPopOver from "@components/common/SummaryPopOver";

interface EmailViewerProps {
	mailState?: MailState | undefined;
	reference: string;
}

const EmailViewer: React.FC<EmailViewerProps> = ({ mailState, reference }) => {
	const { isSidebarHidden, setSidebarHidden, sideBarWidth, isMailListHidden, setMailListHidden, mailListWidth, navigationBarHeight } = useLayout();
	const [wasSidebarHidden, setWasSidebarhidden] = useState<boolean>(isSidebarHidden);
	const { activeMail, setActiveMail, startingPointMail, readerMode, setReaderMode, userMailStates, changeStates, setDigestNeedsReloading } = useData();
	const [nextMail, setNextMail] = useState<NLMail | undefined>();
	const [previousMail, setPreviousMail] = useState<NLMail | undefined>();
	const [skippedMailIds, setSkippedMailIds] = useState<string[]>([]);
	const [initialMailStates, setInitialMailStates] = useState<NLMail[] | undefined>(undefined);
	const previousActiveMail = useRef<NLMail | undefined>(undefined);
	const [navigatingBack, setNavigatingBack] = useState<boolean>(false);
	const { authUser } = useAuth();
	const { bookmarks, bookmarkTags } = useBookmarks();
	const mailDispatch = useMailsDispatch();
	const bookmarkDispatch = useBookmarksDispatch();
	const [formattedHtml, setFormattedHtml] = useState<string | undefined>(undefined);
	const [isReaderModeError, setIsReaderModeError] = useState<boolean>(false);
	const [alertState, setAlertState] = useState<AlertState>({
		isShow: null,
		title: null,
		message: null,
		actionButton: null,
		dismissButton: null,
		actionButtonAction: undefined,
	});
	const [replyMessageState, setReplyMessageState] = useState<ReplyMessageModalState>({
		mail: undefined,
		isShow: false,
	});

	const [bookmarkNoteTagProps, setBookmarkNoteTagProps] = useState<BookmarkTagNoteModalState>({
		bookmark: undefined,
		existingTags: bookmarkTags,
	});
	const [addGroupState, setAddGroupState] = useState<AddGroupState>({
		state: undefined,
		isShow: false,
	});

	var readTimeStartedAt = useRef<Date | undefined>(undefined);

	useHotkeys("RightArrow", () => nextMail && mailState && !mailState.isBookmark && setActiveMail(nextMail));
	useHotkeys("LeftArrow", () => previousMail && mailState && !mailState.isBookmark && setActiveMail(previousMail));
	useHotkeys("f", () => layoutChangeOnClick());
	useHotkeys("d", () => deleteOnClick());

	useEffect(() => {
		if (mailState && startingPointMail) {
			setInitialMailStates(mailState.mails);
		}
	}, [startingPointMail]);

	useEffect(() => {
		return () => {
			logProgress();
		};
	}, []);

	useEffect(() => {
		setIsReaderModeError(false);
		setHtmlToBeDisplayed(activeMail);

		if (navigatingBack) {
			previousActiveMail.current = activeMail;
			setNavigatingBack(false);
			return;
		}

		if (previousActiveMail.current && !skippedMailIds.includes(previousActiveMail.current.id)) {
			const deepCopyMail: NLMail = JSON.parse(JSON.stringify(previousActiveMail.current));
			mailDispatch({ type: "MODIFY_MAILS", messages: [deepCopyMail], action: MesageLabelAction.read });
		}

		logProgress();

		if (activeMail) {
			readTimeStartedAt.current = new Date();
			previousActiveMail.current = activeMail;
			markMailAsRead(activeMail);
		}
	}, [activeMail]);

	useEffect(() => {
		setHtmlToBeDisplayed(activeMail);
		if (!readerMode.state) {
			setIsReaderModeError(false);
		}
	}, [readerMode]);

	useEffect(() => {
		//Set next and previous buttons once we get an update on initialMailStates or activeMail
		setNextMail(activeMail ? getNextMail() : undefined);
		setPreviousMail(activeMail ? getPreviousMail() : undefined);
	}, [initialMailStates, activeMail]);

	useEffect(() => {
		//Sync initial mail states with up to date mail states
		if (!mailState || !startingPointMail) {
			setInitialMailStates(undefined);
			return;
		}

		if (!initialMailStates) {
			setInitialMailStates(mailState.mails);
			return;
		}

		var tempInitialMailStates: NLMail[] = [];
		for (let message of initialMailStates) {
			const existingUpToDateMail = mailState.mails.find((x) => x.id === message.id);
			if (existingUpToDateMail) {
				tempInitialMailStates.push(existingUpToDateMail);
			} else {
				tempInitialMailStates.push(message);
			}
		}

		setInitialMailStates(tempInitialMailStates);
	}, [mailState]);

	const getBarWidth = (): string => {
		var tempSideBarWidth = isSidebarHidden ? 0 : sideBarWidth;
		var tempMailListWidth = isMailListHidden ? 0 : mailListWidth;
		const widthString = "calc(100% - " + (tempSideBarWidth + tempMailListWidth) + "px)";
		return widthString;
	};

	const layoutChangeOnClick = () => {
		if (isMailListHidden) {
			setMailListHidden(false);
			setSidebarHidden(wasSidebarHidden);
		} else {
			setWasSidebarhidden(isSidebarHidden);
			setMailListHidden(true);
			setSidebarHidden(true);
		}
	};

	const logProgress = () => {
		if (!previousActiveMail.current) return;
		const isSkipped = skippedMailIds.includes(previousActiveMail.current.id);
		const eventName = isSkipped ? kAnalyticsConstants.SwipeAway.swipeAwaySkipped : kAnalyticsConstants.App.newsletterRead;
		const deepCopyMail: NLMail = JSON.parse(JSON.stringify(previousActiveMail.current));
		var analyticsNewsletterProperties = generateNewsletterProperties(deepCopyMail, readerMode, reference);
		analyticsNewsletterProperties["is_sidebar_hidden"] = isSidebarHidden;
		analyticsNewsletterProperties["is_fullscreen"] = isMailListHidden;
		var backendNewsletterProperties = generateNewsletterProperties(deepCopyMail, readerMode, reference);
		const receiveDate: number = deepCopyMail.receive_date;
		backendNewsletterProperties[kAnalyticsConstants.App.NewsletterKeys.newsletterReceiveDate] = moment.unix(receiveDate).format("YYYY-MM-DD HH:mm:ss");
		if (readTimeStartedAt.current) {
			const timeDiff = (new Date().getTime() - readTimeStartedAt.current.getTime()) / 1000;
			if (timeDiff < 5 && timeDiff > 3600) {
				return;
			}
			analyticsNewsletterProperties["$duration"] = timeDiff;
			backendNewsletterProperties["duration"] = timeDiff;
		}
		recordEvent(eventName, analyticsNewsletterProperties);
		postAnalyticsEvents(eventName, backendNewsletterProperties);
	};

	const getNextMail = (): NLMail | undefined => {
		if (!(initialMailStates && activeMail)) {
			return;
		}
		const currentActiveMailIndex = initialMailStates.findIndex((x) => x.id === activeMail.id);
		if (currentActiveMailIndex !== -1) {
			const nextMail = initialMailStates[currentActiveMailIndex + 1];
			if (nextMail) {
				return nextMail;
			}
		}
	};

	const getPreviousMail = (): NLMail | undefined => {
		if (!(initialMailStates && activeMail)) {
			return;
		}
		const currentActiveMailIndex = initialMailStates.findIndex((x) => x.id === activeMail.id);
		if (currentActiveMailIndex !== -1) {
			const previousMail = initialMailStates[currentActiveMailIndex - 1];
			if (previousMail) {
				return previousMail;
			}
		}
	};

	const deleteOnClick = () => {
		if (!activeMail) {
			return;
		}

		const deepCopyMail: NLMail = JSON.parse(JSON.stringify(activeMail));
		recordEvent(kAnalyticsConstants.App.newsletterDeleted, generateNewsletterProperties(deepCopyMail, readerMode, reference));
		setActiveMail(nextMail);
		generateToast({ status: "success", message: "Deleted", position: "bottom-center", actionTitle: "UNDO", action: () => undoOnClick(deepCopyMail), onDismiss: () => executeDelete(deepCopyMail) });
		if (!nextMail) {
			setMailListHidden(false);
			setSidebarHidden(wasSidebarHidden);
		}	
	};

	const undoOnClick = (_mail: NLMail) => {};
	const executeDelete = (mail: NLMail) => {
		if (initialMailStates) {
			var tempInitialMailStates = [...initialMailStates];
			tempInitialMailStates = tempInitialMailStates.filter((x) => x.id != mail.id);
			setInitialMailStates(tempInitialMailStates);
		}
		deleteUndeleteMessages([mail], authUser!.mailbox_profiles, true, (success, error) => {});
		mailDispatch({ type: "DELETE_MAILS", messages: [mail] });
	};

	const markMailAsRead = (mail: NLMail) => {
		const deepCopyMail: NLMail = JSON.parse(JSON.stringify(activeMail));
		modifyMessages([deepCopyMail], authUser!.mailbox_profiles, MesageLabelAction.read, () => {});
	};

	const markAsReadOnClick = (mail: NLMail) => {
		const isAlreadyUnread = mail.is_unread ?? false;
		const deepCopyMail: NLMail = JSON.parse(JSON.stringify(activeMail));

		if (skippedMailIds.includes(deepCopyMail.id)) {
			//If skipped
			//Make it unread
			recordEvent(kAnalyticsConstants.App.newsletterRead);
			modifyMessages([deepCopyMail], authUser!.mailbox_profiles, MesageLabelAction.unread, () => {});
			mailDispatch({ type: "MODIFY_MAILS", messages: [deepCopyMail], action: MesageLabelAction.unread });
			var tempSkippedMailIds = [...skippedMailIds];
			setSkippedMailIds(tempSkippedMailIds.filter((x) => x != deepCopyMail.id));
			return;
		}

		if (!isAlreadyUnread) {
			//If already read or skipped
			//Make it skipped
			modifyMessages([deepCopyMail], authUser!.mailbox_profiles, MesageLabelAction.unread, () => {});
			mailDispatch({ type: "MODIFY_MAILS", messages: [deepCopyMail], action: MesageLabelAction.unread });
			var tempSkippedMailIds = [...skippedMailIds];
			tempSkippedMailIds.push(deepCopyMail.id);
			setSkippedMailIds(tempSkippedMailIds);
			generateToast({ message: "This newsletter will remain unread", position: "bottom-center" });
			return;
		}

		if (isAlreadyUnread) {
			//If already unread
			//Keep it unread
			modifyMessages([deepCopyMail], authUser!.mailbox_profiles, MesageLabelAction.unread, () => {});
			mailDispatch({ type: "MODIFY_MAILS", messages: [deepCopyMail], action: MesageLabelAction.unread });
			var tempSkippedMailIds = [...skippedMailIds];
			tempSkippedMailIds.push(deepCopyMail.id);
			setSkippedMailIds(tempSkippedMailIds);
			generateToast({ message: "This newsletter will remain unread", position: "bottom-center" });
			return;
		}
	};

	const backArrowOnClick = (mail: NLMail | undefined) => {
		if (activeMail && (activeMail.is_unread ?? false)) {
			const deepCopyMail: NLMail = JSON.parse(JSON.stringify(activeMail));
			modifyMessages([deepCopyMail], authUser!.mailbox_profiles, MesageLabelAction.unread, () => {});
		}
		setNavigatingBack(true);
		setActiveMail(mail);
	};

	const getBookmark = (mail: NLMail): NLBookmark | undefined => {
		if (!bookmarks) {
			return undefined;
		}

		const bookmark = bookmarks.find((x) => x.mail_id === mail.id);
		if (bookmark && bookmark.bookmark_star) {
			return bookmark;
		}

		return undefined;
	};

	const bookmarkOnClick = (e) => {
		var currenTarget = e.currentTarget;

		if (!activeMail) {
			return;
		}

		const bookmark = getBookmark(activeMail);
		const deepCopyMail: NLMail = JSON.parse(JSON.stringify(activeMail));

		recordEvent(bookmark ? kAnalyticsConstants.App.newsletterUnstarred : kAnalyticsConstants.App.newsletterStarred, generateNewsletterProperties(deepCopyMail, readerMode, reference));

		if (bookmark) {
			//This is a delete
			var modifiedBookmarkObject = { ...bookmark };
			modifiedBookmarkObject.is_deleted = true;
			updateBookmark(modifiedBookmarkObject);
			//self.trackAnalyticsEvent(name: action == .starred ? kAnalyticsConstants.App.newsletterStarred : kAnalyticsConstants.App.newsletterUnstarred,
			// properties: self.generateAnalyticsProperties(mail: mail))
			bookmarkDispatch({ type: "MODIFY_BOOKMARK", payload: { updatedBookmark: modifiedBookmarkObject } });
			generateToast({ status: "success", message: "Removed from bookmarks", position: "bottom-center" });
			return;
		}

		var newBookmark = new NLBookmark();
		newBookmark.mail_sender_name = deepCopyMail.sender_name;
		newBookmark.mail_sender_address = deepCopyMail.sender_address;
		newBookmark.mail_subject = deepCopyMail.subject;
		newBookmark.mail_id = deepCopyMail.id;
		newBookmark.bookmark_star = new NLBookmarkStar();

		currenTarget.disabled = true;
		postBookmarks([newBookmark])
			.then((bookmarks) => {
				bookmarkDispatch({ type: "ADD_BOOKMARKS", payload: { bookmarks: bookmarks } });
				generateToast({ status: "success", message: "Added to bookmarks", position: "bottom-center", actionTitle: "TAG & NOTE", action: () => bookmarkTagNoteOnClick(bookmarks[0]) });
			})
			.catch((_error) => {
				generateToast({ status: "error", message: "Failed to add to bookmarks", position: "bottom-center" });
			})
			.finally(() => {
				currenTarget.disabled = false;
			});
	};

	const bookmarkTagNoteOnClick = (bookmark: NLBookmark | undefined) => {
		if (!bookmark) {
			return;
		}
		setBookmarkNoteTagProps({ bookmark: bookmark, existingTags: bookmarkTags });
	};

	const unsubscribeFromSender = (mail: NLMail) => {
		recordEvent(kAnalyticsConstants.App.newsletterUnsubscribed, { [kAnalyticsConstants.App.NewsletterKeys.newsletterSenderAddress]: mail.sender_address ?? "", [kAnalyticsConstants.App.NewsletterKeys.newsletterSenderName]: mail.sender_name ?? "" });
		const mailboxProfile = authUser!.mailbox_profiles.find((x) => x.id === mail.mailbox_profile_id);
		if (!mailboxProfile || !mail.sender_address) return;
		const userMailState = userMailStates?.find((x) => x.sender_address.toLowerCase() === mail.sender_address!.toLowerCase() && x.mailbox_profile_id === mailboxProfile.id);
		if (!userMailState) return;
		var oauthSpecificCopy = "";
		if (mailboxProfile.type != NLMailboxProfileType.Internal) {
			oauthSpecificCopy = ` Their old newsletter issues would be archived and accessible from your ${mailboxProfile.type === NLMailboxProfileType.Gmail ? "Gmail" : "Outlook"}.`;
		}
		setAlertState({
			isShow: true,
			title: `Remove ${mail.sender_name} from Meco?`,
			message: `Since you unsubscribed from ${mail.sender_name}, we recommend removing their newsletters from Meco to declutter your experience.${oauthSpecificCopy}`,
			actionButton: "Remove sender from Meco",
			dismissButton: "Keep sender in Meco",
			actionButtonAction: () => {
				changeStates([mailboxProfile], [userMailState], NLUserMailStateNames.inbox, (error) => {
					if (error) return;
					setDigestNeedsReloading(true);
				});
			},
		});
		window.open(mail.list_unsubscribe, "_blank");
	};

	const getReceiveDate = (receiveDate: number): string => {
		const unixReceiveDate = moment.unix(receiveDate);
		return unixReceiveDate.calendar(null, {
			lastDay: "[Yesterday at] HH:mm",
			sameDay: "[Today at] HH:mm",
			nextDay: "[Tomorrow at] HH:mm",
			lastWeek: "[Last] dddd [at] HH:mm",
			nextWeek: "dddd [at] HH:mm",
			sameElse: "ddd, D MMM, HH:mm",
		});
	};

	const getOptions = (mail: NLMail) => {
		const userMailState = userMailStates?.find((x) => x.sender_address === mail.sender_address && x.mailbox_profile_id === mail.mailbox_profile_id);

		const shareItem = {
			name: "Share",
			icon: "link_icon",
			action: () => {
				postShareMail(mail).then((shareUrl) => {
					recordEvent(kAnalyticsConstants.EmailViewer.newsletterLinkShared, generateNewsletterProperties(mail, readerMode, reference));
					setTimeout(() => {
						navigator.clipboard.writeText(shareUrl);
					}, 0);
					generateToast({ status: "success", message: "Share link copied to clipboard", position: "bottom-center" });
				});
			},
		};

		const replyItem = {
			name: "Reply",
			icon: "reply_icon",
			action: () => {
				setReplyMessageState({  mail: mail, isShow: true });
			},
		};

		var dropDownOptions: DropdownItem[] = [shareItem, replyItem];

		if (userMailState) {
			const addToGroupItem = { name: "Add sender to group", icon: "folder_icon", action: () => setAddGroupState({ state: { ...userMailState }, isShow: true }) };
			dropDownOptions.push(addToGroupItem);
		}

		if (mail.list_unsubscribe) {
			const unsubscribeItem = { name: "Unsubscribe from this sender", icon: "unsubscribe_icon", action: () => unsubscribeFromSender(mail) };
			dropDownOptions.push(unsubscribeItem);
		}

		return dropDownOptions;
	};

	const addReaderModeToBody = (htmlString): string => {
		const bodyStartIndex = htmlString.indexOf("<body");
		if (bodyStartIndex !== -1) {
			const modifiedHtmlString = htmlString.slice(0, bodyStartIndex + 5) + ` class=${readerMode.theme}  style="--font-size: ${readerMode.fontSize}px; --font-family: ${readerMode.font};"` + htmlString.slice(bodyStartIndex + 5);
			return modifiedHtmlString;
		}
		return htmlString;
	};

	const setHtmlToBeDisplayed = (mail: NLMail | undefined) => {
		if (!mail) {
			setFormattedHtml(undefined);
			return;
		}

		var basicStyling = '<html style="margin-top: ' + navigationBarHeight + 'px !important;"><head><base target="_blank"></head></html>' + " ";
		if (!mail) {
			setFormattedHtml(undefined);
			return;
		}

		if (readerMode.state) {
			if (mail.reader_mode_html) {
				setFormattedHtml(basicStyling + `<style>@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&family=Gelasio&family=Literata:opsz@7..72&family=Lora&family=Open+Sans:ital@0;1&display=swap'); ${readerModeStyles}</style>` + addReaderModeToBody(mail.reader_mode_html));
				return;
			}

			const mailboxProfile = authUser!.mailbox_profiles.find((x) => x.id === mail.mailbox_profile_id);
			if (!mailboxProfile) {
				setFormattedHtml(basicStyling + `<style>${defaultStyles}</style>` + mail.body_html);
				return;
			}

			setFormattedHtml(undefined);

			const readerModeService = new ReaderModeService(mailboxProfile, mail.id, mail.sender_address);
			readerModeService.getMessageWithReaderMode((readerModeHtml, error) => {
				if (error || !readerModeHtml) {
					setIsReaderModeError(true);
					setFormattedHtml(basicStyling + `<style>${defaultStyles}</style>` + mail.body_html);
					return;
				}
				mailDispatch({ type: "SET_READER_MODE", message: mail, readerModeHtml: readerModeHtml });
				setFormattedHtml(basicStyling + `<style>@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&family=Gelasio&family=Literata:opsz@7..72&family=Lora&family=Open+Sans:ital@0;1&display=swap'); ${readerModeStyles}</style>` + addReaderModeToBody(readerModeHtml));
			});
		} else {
			setFormattedHtml(basicStyling + `<style>${defaultStyles}</style>` + mail.body_html);
		}
	};

	return (
		<div className="w-full">
			{activeMail ? (
				<>
					<Alert alertState={alertState} setAlertState={setAlertState} />
					<AddGroupModal addGroupState={addGroupState} setAddGroupState={setAddGroupState} />
					<ReplyMessageModal replyMessageState={replyMessageState} setReplyMessageState={setReplyMessageState} />
					<BookmarkTagNoteModal bookmarkTagNoteModalState={bookmarkNoteTagProps} onClose={() => setBookmarkNoteTagProps({ ...bookmarkNoteTagProps, bookmark: undefined })} />
					<div className="flex flex-col h-screen overflow-hidden">
						<div className="fixed z-20" style={{ width: getBarWidth(), height: navigationBarHeight + "px" }}>
							<div className="flex flex-col gap-3 items-center bg-contrast/80 border-b border-primary-100 backdrop-blur-md justify-center h-full">
								<div className="flex flex-row justify-between gap-2 w-full px-3 items-center">
									<div className="flex flex-row gap-2 items-center">
										<IconButton Icon={isMailListHidden ? FullScreenInvertedIcon : FullScreenIcon} onClick={() => layoutChangeOnClick()} />
										{mailState && !mailState.isBookmark && (
											<>
												<div className="w-[1px] h-[40px] bg-primary-100 mx-1" />
												<IconButton disabled={previousMail === undefined} Icon={LeftArrowIcon} onClick={() => backArrowOnClick(previousMail)} />
												<IconButton disabled={nextMail === undefined} Icon={LeftArrowIcon} iconClassName="rotate-180" onClick={() => setActiveMail(nextMail)} />
											</>
										)}
									</div>
									<div className="flex flex-col items-center text-center max-w-sm px-2 min-w-0">
										<div className="font-primary font-regular text-xs text-primary whitespace-nowrap overflow-hidden text-ellipsis w-full">{activeMail.sender_name}</div>
										<div className="font-primary font-bold text-xs text-primary whitespace-nowrap overflow-hidden text-ellipsis w-full">{activeMail.subject}</div>
										<div className="text-2xs font-primary font-regular text-primary/50 whitespace-nowrap overflow-hidden text-ellipsis w-full">{getReceiveDate(activeMail.receive_date)}</div>
									</div>
									<div className="flex flex-row gap-2 items-center justify-end">
										<SummaryPopOver mail={activeMail} />
										<ReaderModePopOver readerMode={readerMode} setReaderMode={setReaderMode} isError={isReaderModeError} />
										<div className="w-[1px] h-[40px] bg-primary-100 mx-1" />
										<div className="flex flex-row bg-success-green/20 border border-primary-100 px-2 py-1 rounded-lg items-center gap-2">
											<IconButton Icon={DeleteIcon} iconClassName="group-hover:fill-brand-red" onClick={() => deleteOnClick()} />
											<IconButton Icon={getBookmark(activeMail) ? BookmarkOnIcon : BookmarkOffIcon} iconClassName="group-hover:fill-success-green" onClick={(e) => bookmarkOnClick(e)} />
											<EmailCheckMark state={skippedMailIds.includes(activeMail.id) ? "disabled" : activeMail.is_unread ?? false ? "unread" : "read"} onClick={() => markAsReadOnClick(activeMail)} />
											<Dropdown buttonIcon={OptionsIcon} items={getOptions(activeMail)} />
										</div>
									</div>
								</div>
							</div>
						</div>
						<AnimatePresence exitBeforeEnter>
							<motion.div key={activeMail.id} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2, ease: "easeInOut" }}>
								{activeMail.body_html && (
									<>
										<iframe title={activeMail.subject} className="bg-white" srcDoc={formattedHtml} style={{ width: 100 + "%", height: 100 + "vh" }}></iframe>
									</>
								)}
							</motion.div>
						</AnimatePresence>
					</div>
				</>
			) : (
				<EmptyStateView title="No newsletter selected" description="Select a newsletter to read" />
			)}
		</div>
	);
};

export default EmailViewer;
